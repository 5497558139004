@import url('@nimbus-ds/tokens/dist/css/variables.css');

:root {
  --background: var(--color-sys-light-neutral-surface);
}

* {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: initial;
}

html,
body {
  max-width: 100vw;
}

body {
  background-color: var(--background);
}
